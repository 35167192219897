@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Lato Light Italic'), url('./assets/fonts/Lato-LightItalic.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), url('./assets/fonts/Lato-Regular.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), url('./assets/fonts/Lato-Bold.ttf');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Lato Black'), url('./assets/fonts/Lato-Black.ttf');
}

@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended Regular'), local('Monument-Extended-Regular'),
    url('./assets/fonts/MonumentExtended-Regular.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

#gradient-canvas {
  width:100%;
  height:100%;
  position: absolute;
  --gradient-color-1: #FFE9EA; 
  --gradient-color-2: #84B7FF; 
  --gradient-color-3: #C7B7FF;  
  --gradient-color-4: #97F5DE;
  z-index:0;
}